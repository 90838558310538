import React, {CSSProperties, FC, ReactElement} from 'react';
import classNames from 'classnames'

import styles from "./Status.module.scss";

export interface StatusProps {
   text: string | HTMLElement | ReactElement;
   color?: keyof typeof StatusColors;
   size?: keyof typeof StatusSize;
   shape?: keyof typeof StatusShape;
   className?: string;
   styles?: CSSProperties;
}

export enum StatusColors {
   HIGHLIGHT = "HIGHLIGHT",
   LIGHT_HIGHLIGHT = "LIGHT_HIGHLIGHT",
   ERROR = "ERROR",
   WARNING = "WARNING",
   VERIFICATION = "VERIFICATION",
   GREEN = "GREEN",
   DISABLED = "DISABLED",
   BLACK = "BLACK",
   RED = "RED",
}

export enum StatusSize {
   FULL = "FULL",
   AUTO = "AUTO"
}

export enum StatusShape {
   ROUNDED = 'ROUNDED',
   SQUARE = 'SQUARE',
   BOTTOM_ROUNDED = 'BOTTOM_ROUNDED'
}

export const Status: FC<StatusProps> = ({
   text,
   color= StatusColors.HIGHLIGHT,
   size= StatusSize.AUTO,
   className,
   styles: styleWrapper,
   shape= StatusShape.ROUNDED
}) => {
   const statusClasses =
      classNames(
         styles.status,
         className,
         {
            [styles.highlight]: color === StatusColors.HIGHLIGHT,
            [styles.lightHighlight]: color === StatusColors.LIGHT_HIGHLIGHT,
            [styles.error]: color === StatusColors.ERROR,
            [styles.warning]: color === StatusColors.WARNING,
            [styles.verification]: color === StatusColors.VERIFICATION,
            [styles.green]: color === StatusColors.GREEN,
            [styles.disabled]: color === StatusColors.DISABLED,
            [styles.black]: color === StatusColors.BLACK,
            [styles.red]: color === StatusColors.RED,

            [styles.full]: size === StatusSize.FULL,
            [styles.auto]: size === StatusSize.AUTO,

            [styles.rounded]: shape === StatusShape.ROUNDED,
            [styles.square]: shape === StatusShape.SQUARE,
            [styles.roundedBottom]: shape === StatusShape.BOTTOM_ROUNDED,
         }
      );

   return (
      <div className={statusClasses} style={styleWrapper}>{text}</div>
   )
}
