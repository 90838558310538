import React from 'react';
import {getDate, getMonth, getYear} from '../../../utils/react';
import { months } from '../../../utils/constants';
import styles from './Month.module.scss';


interface State {
    monthsArr: Array<string>,
    minMonth?: any,
    maxMonth?: any,
}

interface Props {
    selectedDate?: any;
    numberOfDaysPerMonth?: number,
    onClick: any,
    dateRange: any;
    lang: string;
}

class Month extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);

        // Here should be the date in the format ["JJJJ-MM-TTT12:00:00.000Z", "JJJJ-MM-TTT12:00:00.000Z"]
        // const date = [];
        if (this.props.dateRange && this.props.dateRange.length > 0 && (getYear(this.props.dateRange[0]) === getYear(this.props.dateRange[1]))) {
            console.log('years are equal');
            const minMonth = getMonth(this.props.dateRange[0]);
            const maxMonth = getMonth(this.props.dateRange[1]);
            this.state = {
                monthsArr: [...months("en")],
                minMonth: minMonth,
                maxMonth: maxMonth,
            }
        } else {
            this.state = {
                monthsArr: [...months("en")],
            }
        }
    }

    render() {
        return (
            <div className={styles.months}>
                {this.state.monthsArr.map((month, i) => {
                    const monthNumber = i + 1;
                    const id: string = monthNumber < 10 ? `0${monthNumber}` : monthNumber.toString();
                    const { selectedDate } = this.props;
                    const isInRange = (this.props.dateRange && (getYear(this.props.dateRange[0]) === getYear(this.props.dateRange[1])) && (getMonth(this.props.dateRange[0]) || getMonth(this.props.dateRange[1])));
                    if (isInRange) {
                        const isInDailyRange = selectedDate && selectedDate.TT && (getMonth(this.props.dateRange[1]) == i) ? (Number(selectedDate.TT) <= getDate(this.props.dateRange[1])) : true
                        const isInMonthlyRange = (i >= getMonth(this.props.dateRange[0]) && i <= getMonth(this.props.dateRange[1]));

                        return isInMonthlyRange && isInDailyRange ? (
                            <div className={styles.month}
                                id={id}
                                onClick={(e) => this.props.onClick(e)}
                                key={month.toString()}>
                                {month}
                            </div>) : null
                    } else {
                        return (
                            <div className={styles.month}
                                id={id}
                                onClick={(e) => this.props.onClick(e)}
                                key={month.toString()}>
                                {month}
                            </div>)
                    }
                }
                )}

            </div>
        );
    }
}

export default Month;
