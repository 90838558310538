import React from 'react'
import classNames from 'classnames'

import styles from './ExpandableRow.module.scss'
import {ReactComponent as IconArrowDown} from '../../assets/arrow-down.svg'
import {Status, StatusColors, StatusProps, StatusShape, StatusSize} from "../Status";

interface State {
   expanded: boolean;
   expandedError: boolean;
}

interface Props {
   content: any;
   more?: any;
   rowChanged?: any;
   className?: string;
   expanded?: number;
   index?: number;
   length?: number;
   progress?: boolean;
   expandable?: boolean;
   translucent?: boolean;
   hasBeenDownloaded?: boolean;
   progressbar?: any;
   isDisabled?: boolean;
   isExpanded?: boolean;
   hasNoArrow?: boolean;
   groupPosition?: string;
   statusBar?: StatusProps;
   errorMoreContent?: any;
}

export const GroupPositions = {
   Top: "top",
   Middle: "middle",
   Bottom: "bottom",
}

export class ExpandableRow extends React.Component<Props, State> {
   constructor(props: any) {
      super(props)
      this.state = {
         expanded: props.isExpanded === true,
         expandedError: false
      }
   }

   shouldComponentUpdate(nextProps, nextState) {
      const {expanded} = this.state;
      const {index, hasBeenDownloaded, length} = this.props;

      if ((nextProps.expanded != index) && expanded) {
         this.setState({expanded: false});
      }

      return !((nextProps.expanded != index) && !expanded) ||
         nextProps.length !== length || nextProps.progress || (hasBeenDownloaded == false);
   }

   handleClick = () => {
      const {expanded, expandedError} = this.state;
      const {isExpanded, hasNoArrow, rowChanged} = this.props;

      if (isExpanded !== true && hasNoArrow !== true) {
         rowChanged && rowChanged(expanded && expandedError ? expanded : !expanded)
         this.setState({expanded: expanded && expandedError ? expanded : !expanded},
            () => {
            if(expanded && expandedError) {
               this.setState({
                  expandedError: false
               });
            }
         });
      }
   }

   handleErrorClick = () => {
      const {expanded, expandedError} = this.state;
      const {rowChanged, errorMoreContent} = this.props;

      if(!errorMoreContent) {
         return;
      }

      this.setState({
         expandedError: !expandedError
      }, () => {
         rowChanged && rowChanged(expanded && !expandedError ? expanded : !expandedError)
         console.log(expandedError);
         this.setState({
            expanded: expanded && !expandedError ? expanded : !expandedError
         });
      });

   }

   handleKeyDown = event => {
      if (event.keyCode === 13) {
         this.handleClick();
      }
   }

   render() {
      const {expanded, expandedError} = this.state
      const {
         expandable,
         className,
         content,
         more,
         isDisabled,
         isExpanded,
         hasNoArrow,
         groupPosition,
         statusBar,
         errorMoreContent,
      } = this.props

      const classes = classNames(styles.expandableRow, className,
         {[styles.expanded]: expanded || isExpanded},
         {[styles.expandedError]: expandedError},
         {[styles.expandable]: expandable && (isExpanded !== true) && (hasNoArrow !== true)},
         {[styles.disabled]: isDisabled},
         {[styles.groupPositionTop]: groupPosition === GroupPositions.Top},
         {[styles.groupPositionMiddle]: groupPosition === GroupPositions.Middle},
         {[styles.groupPositionBottom]: groupPosition === GroupPositions.Bottom},
      );

      return (
         <div className={classes} tabIndex={0} onKeyDown={this.handleKeyDown}>
            <div className={classNames(styles.top)} onClick={this.handleClick}>
               <div className={styles.content}>{content}</div>

               {expandable && (isExpanded !== true) && (hasNoArrow !== true) &&
               <div className={classNames(styles.expandableRowArrow)}>
                  <IconArrowDown/>
               </div>}
            </div>

            {expandable && <div className={styles.more}>{more}</div>}

            {statusBar && statusBar.text && (
               <div className={classNames(styles.expandableRowStatusBar,
                  { [styles.errorExpandable]: errorMoreContent},
                  { [styles.rounded]: !groupPosition || groupPosition == GroupPositions.Bottom },
                  { [styles.error]: statusBar.color == StatusColors.ERROR },
                  { [styles.success]: statusBar.color == StatusColors.VERIFICATION },
                  { [styles.warning]: statusBar.color == StatusColors.WARNING },
               )} onClick={this.handleErrorClick}>
                  {statusBar.text}

                  {errorMoreContent &&
                  <div className={classNames(styles.errorArrow)}>
                     <IconArrowDown/>
                  </div>}
               </div>
            )}

            {errorMoreContent && <div className={styles.errorMoreContent}>{errorMoreContent}</div>}
         </div>
      )
   }
}
