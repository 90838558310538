import * as React from 'react'
import classNames from 'classnames'

import styles from './Text.module.scss'
import {EInputTextType} from '../index'
import {exists} from '../../../utils/variableEvaluation'
import {ReactComponent as IconSearch} from '../../../assets/search.svg'
import {ReactComponent as IconCalendar} from '../../../assets/calendar.svg'
import {ReactComponent as GreenCheckMark} from '../../../assets/green-check-mark.svg'
import {formatDate, formatMonth, formatNumber, formatYear} from '../../../utils/react'
import {ValidationMessage} from '../../ValidationMessage'

interface IProps {
   isFocused: boolean;
   isLoading: boolean;
   name?: string;
   value?: string[] | string | number;
   placeholder: string;
   type: string;
   onChange?: (name: string, value: string) => void;
   additionalClass?: string;
   isDisabled?: boolean;
   inputTextType?: EInputTextType;
   onBlur?: any;
   autoFocus?: boolean;
   readOnly?: boolean;
   maxLength?: number;
   min?: number;
   max?: number;
   step?: string;
   filtering?: boolean;
   isDropdown?: boolean;
   validationMessageConfig?: ValidationMessage;
   numeric?: boolean;
   id?: string;
}

interface IState {
   isFocused: boolean;
}

export class InputText extends React.Component<IProps, IState> {
   private inputRef: HTMLInputElement;
   private focusTimeoutId;

   constructor(props: any) {
      super(props)
      this.state = {
         isFocused: false
      }
   }

   public componentDidMount() {
      this.focusTimeoutId = setTimeout(() => {
         if (this.props.isFocused) {
            this.inputRef.focus()
         }
      }, 0)
   }

   public componentWillUnmount() {
      clearTimeout(this.focusTimeoutId)
   }

   private handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const name = e.currentTarget.name;
      const value = e.currentTarget.value;
      // console.log("handleChange", name, value);
      const determinedValue = this.determineValue(value);
      const finalValue = typeof determinedValue == "undefined" ? "" : determinedValue;
      this.props.onChange && this.props.onChange(name, finalValue);
   };

   private handleFocus = (e: React.ChangeEvent<HTMLInputElement>) => {
      console.log('handleFocus')
      this.setState({isFocused: true})
   }

   private handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
      const name = e.currentTarget.name;
      const value = e.currentTarget.value;
      console.log("blurred");
      console.log(this.determineValue(value))
      const determinedValue = this.determineValue(value);

      if (this.props.type == "date") {
         this.setState({isFocused: false}, () => {
            const determinedValue = this.determineValue(value);
            const finalValue = typeof determinedValue == "undefined" ? "" : determinedValue;
            this.props.onChange(name, finalValue)
         });
      } else {
         this.setState({isFocused: false});
      }

      this.props.onBlur && this.props.onBlur(e);
   };

   determineValue = (val) => {
      const {isFocused} = this.state
      const {type} = this.props

      if (type === 'date') {
         return formatDate(val, isFocused)
      } else if (type === 'year') {
         return formatYear(val)
      } else if (type === 'month') {
         return formatMonth(val)
      } else {
         if (isFocused) {
            return val
         } else {
            if (type === 'money') {
               return formatNumber(val)
            } else {
               return val
            }
         }
      }
   }

   public render() {
      const {
         name,
         value,
         placeholder,
         type,
         isFocused,
         isLoading,
         additionalClass,
         isDisabled,
         readOnly,
         min,
         max,
         step,
         maxLength,
         filtering,
         isDropdown,
         validationMessageConfig,
         numeric,
         id
      } = this.props

      const showCalendarIcon = type === 'date' || type === 'year' || type === 'month'

      const inputClass = classNames(
         styles.textInput,
         {
            [additionalClass]: exists(additionalClass),
            [styles.hasValue]: exists(value),
            [styles.pr20]: exists(validationMessageConfig?.config?.type === "success"),
            [styles.disabled]: isDisabled,
         }
      );

      const inputType = (type) => {
         if(numeric) {
            return "tel";
         } else if(type === 'money' || type === 'date' || type === 'month' || type === 'year') {
            return "text"
         } else {
            return type;
         }
      }

      return (
         <div className={classNames(styles.textInputContainer, {[styles.disabledContainer]: isDisabled})}>
            <input
               id={id}
               name={name}
               type={inputType(type)}
               min={min}
               max={max}
               step={step}
               readOnly={readOnly}
               autoFocus={isFocused}
               className={inputClass}
               onBlur={this.handleBlur}
               placeholder={placeholder}
               onFocus={this.handleFocus}
               onChange={this.handleChange}
               maxLength={maxLength || 255}
               disabled={isDisabled || isLoading}
               value={this.determineValue(value)}
               ref={input => isFocused && (this.inputRef = input)}
               autoComplete="off"
            />

            <div
               className={classNames({
                  [styles.filtering]: filtering,
                  [styles.dropdown]: isDropdown,
                  [styles.language]: name == 'languageInput'
               })}
            />

            {!filtering && !isDropdown && validationMessageConfig?.config?.type === "success" && (
               <GreenCheckMark
                  className={`${styles.greenCheckBox} ${showCalendarIcon ? styles.greenCheckBoxRight : ""}`}
               />
            )}

            {!isDropdown ? showCalendarIcon ? <IconCalendar className={styles.calendar}/> : filtering ? <IconSearch/> : null : null}
         </div>
      )
   }
}
