import * as React from 'react';
import classNames from 'classnames';
import styles from './InputFile.module.scss'; // Import css modules stylesheet as styles

interface IProps {
    isFocused: boolean;
    isLoading: boolean;
    name?: string;
    value?: string[] | string | number;
    placeholder: string;
    type: string;
    onChange?: (name: string, value: any, file?: any) => void;
    additionalClass?: string;
    isDisabled?: boolean;
    autoFocus?: boolean;
    readOnly?: boolean;
    inputTextType?: any;
    id?: any;
    label?: string
   onBlur?: any;
}

interface IState {
    isFocused: boolean;
    fileName: string;
}

export class InputFile extends React.Component<IProps, IState> {
    constructor(props: any) {
        super(props);
        this.state = {
            isFocused: false,
            fileName: ""
        }
    }
    private inputRef: HTMLInputElement;
    private focusTimeoutId;

    public componentDidMount() {
        this.focusTimeoutId = setTimeout(() => {
            if (this.props.isFocused) {
                this.inputRef.focus();
            }
        }, 0);
    }

    public componentWillUnmount() {
        clearTimeout(this.focusTimeoutId);
    }

    private handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;
        const file = e.target.files && e.target.files[0] ? e.target.files[0] : null
        console.log("handleChange", name, value);

        this.setState({ fileName: file ? file.name : "" });

        console.log("FILES", file);

        console.log("FILES", e.target.files[0]);


        this.props.onChange && this.props.onChange(name, value, file);
    };

    public render() {
        const {
            name,
            value,
            type,
            isFocused,
            isLoading,
            isDisabled,
            readOnly,
            id,
            label,
            placeholder,
           onBlur
        } = this.props;

        const { fileName } = this.state;

        return (
            <label htmlFor={id} className={styles.inputFileWrapper}>
                <span className={styles.inputFileButton}>{label || "Datei auswählen"}</span>
                <span className={`${styles.inputFileName} ${fileName && styles.hasFile}`}>
                    {fileName ? fileName : placeholder ? placeholder : "Keine Datei ausgewählt"}
                </span>
                <input
                    id={id}
                    name={name}
                    type={type}
                    value={value}
                    onBlur={onBlur}
                    readOnly={readOnly}
                    onChange={this.handleChange}
                    className={styles.inputFile}
                    ref={input => isFocused && (this.inputRef = input)}
                    autoFocus={isFocused}
                    disabled={isDisabled || isLoading}
                    autoComplete="off"
                />
                <div></div>
            </label>
        );
    }
}
