const theme = {
  colors: {
    content: "#242F38",
    action: "#145685",
    actionDarker: "#10446A",
    highlight: "#1B8DB3",
    lightHighlight: "#75B8CF",
    distinct: "#77B324",
    grey: "#969899",
    greyDisabled: "#C4C6C7",
    greyMedium: "#D7D8D9", 
    greyLight: "#E3E5E6",
    greyLighter: "#EEEFF0",
    error: "#DD1F4A",
    errorDarker: "#991533",
    warning: "#E67417",
    verification: "#138057",
    white: "#ffffff",
  },
  fonts: {
    regular: "Inter",
    medium: "Inter Medium",
    semiBold: "Inter SemiBold",
    bold: "Inter Bold",
  },
  fontSize: {
    huge: "48px",
    smallerHuge: "40px",
    big: "36px",
    large: "24px",
    biggerMedium: "20px",
    medium: "18px",
    regular: "16px",
    small: "14px",
    tiny: "12px",
  },
  lineHeight: {
    huge: "56px",
    smallerHuge: "48px",
    big: "44px",
    large: "32px",
    biggerMedium: "28px",
    medium: "26px",
    regular: "24px",
    small: "20px",
    tiny: "18px",
  }
};

export { theme };
