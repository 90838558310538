export const exists = x => Boolean(x);

export const defined = x => typeof x !== 'undefined' && x !== null;

export const isArray = x => x.constructor === Array;

export const isString = x => typeof x === 'string';

export const isNumber = x => typeof x === 'number';

export const formatDate = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}
