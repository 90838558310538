import * as React from 'react';
import classNames from 'classnames';

import styles from './Option.module.scss';

export interface Props {
   value: number | string;
   label: string;
   selected?: boolean;
   onOptionClick: (value: string, e: any) => void;
}

export default function Option(props: Props) {
   const { label, onOptionClick, selected } = props
   const classes = classNames(styles.option, "inputSelectOption", { [styles.active]: selected });

   return (
      <div
         className={classes}
         onMouseDown={(e) => onOptionClick(label, e)}
         onMouseUp={(e) => onOptionClick('close', e)}
      >
         {label}
      </div>
   )
}
