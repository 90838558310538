import styled from '@emotion/styled'

export const StyledSticky = styled.div`
   display: flex;
   align-items: center;
   justify-content: space-between;
   width: 100%;
   padding: 2rem;
   border-radius: .8rem;
   background-color: ${({theme})=>theme.colors.action};
   box-shadow: 0 .8rem 2rem 0 rgba(36,47,56,0.5);

   &:hover {
      background-color:${({theme})=>theme.colors.actionDarker};
   }
`

export const StyledContent = styled.div`
    width: auto;
`