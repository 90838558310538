import React from 'react'
import * as S from './styles'
import classNames from 'classnames'
import {defined} from '../../utils/variableEvaluation'

export enum ButtonTypes {
    Standard = 'Standard',
    Text = 'Text',
    Icon = 'Icon'
 }
 
 export enum ButtonStyles {
    Primary = 'Primary',
    Secondary = 'Secondary',
    Sticky = 'Sticky'
 }
 
 export enum ButtonSizes {
    Auto = 'Auto',
    Full = 'Full',
    ExtraSmall = 'ExtraSmall',
    Small = 'Small',
    Medium = 'Medium',
    Large = 'Large',
    ExtraLarge = 'ExtraLarge'
 }
 
 export enum ButtonColors {
    Action = "Action",
    Error = "Error",
    White = "White",
    WhiteInvert = "WhiteInvert",
 }
 
 export enum Actions {
    InternalLink = 'InternalLink',
    ExternalLink = 'ExternalLink',
    NormalButton = 'NormalButton'
 }

interface IProps {
    type?: ButtonTypes;
    style?: ButtonStyles;
    color?: ButtonColors;
    size?: ButtonSizes;
    to?: string;
    href?: string;
    isLoading?: boolean;
    isDisabled?: boolean;
    submit?: boolean;
    className?: string;
    onClick?: any;
    focusable?: boolean;
    icon?: any;
    disableLabel?: any;
    id?: string;
    children: React.ReactNode;
 }

export const Button = ({
    type, 
    style, 
    color, 
    size, 
    to, 
    href, 
    isLoading, 
    isDisabled, 
    submit, 
    className, 
    onClick, 
    focusable, 
    icon, 
    disableLabel, 
    id, 
    children
}:IProps) => {
    const action = () => {
        if (defined(to)) {
           return Actions.InternalLink
        } else if (defined(href)) {
           return Actions.ExternalLink
        } else {
           return Actions.NormalButton
        }
    }

    const buttonClass = classNames(className, {
        ['standardButton']: type === ButtonTypes.Standard,
        ['textButton']: type === ButtonTypes.Text,
        ['iconButton']: type === ButtonTypes.Icon,

        ['primaryButton']: style === ButtonStyles.Primary,
        ['secondaryButton']: style === ButtonStyles.Secondary,
        ['stickyButton']: style === ButtonStyles.Sticky,

        ['action']: color === ButtonColors.Action || !color,
        ['error']: color === ButtonColors.Error,
        ['white']: color === ButtonColors.White,
        ['whiteInvert']: color === ButtonColors.WhiteInvert,

        ['extraSmall']: size === ButtonSizes.ExtraSmall,
        ['small']: size === ButtonSizes.Small,
        ['medium']: size === ButtonSizes.Medium,
        ['large']: size === ButtonSizes.Large,
        ['extraLarge']: size === ButtonSizes.ExtraLarge,
        ['auto']: size === ButtonSizes.Auto,
        ['full']: size === ButtonSizes.Full,

        ['disabled']: isDisabled,
        ['icon']: icon,
     });


    const renderButton = () => {
        if(action()===Actions.InternalLink){
            return <S.InternalLink 
                key={`Link-${to}`}
                to={(to as string)}
                className={buttonClass}
                role="button"
            >{children}</S.InternalLink>
        }else if(action()===Actions.ExternalLink){
            return <S.ExternalLink
                tabIndex={0}
                key={`a-${href}`}
                href={href}
                className={buttonClass}
                role="button"
                target="_blank"
                rel="noopener noreferrer"
            >{children}</S.ExternalLink>
        }else{
            return <S.ButtonContainer 
                id={id}
                tabIndex={focusable == false ? -1 : 0}
                key="button"
                className={buttonClass}
                disabled={isDisabled}
                onClick={onClick}
                type={submit ? 'submit' : 'button'}
            >
                {children}
                {icon && <div className={'icon'}>{icon}</div>}
                {isDisabled && disableLabel && <div className={'disableLabel'}>{disableLabel}</div>}
            </S.ButtonContainer>
        }
    }
    
    return renderButton()
    
}