import React from 'react'
import classNames from 'classnames'

import styles from './StickyActions.module.scss'
import {Button, ButtonColors, ButtonTypes} from "../Button";

interface State {
   expanded: boolean;
}

interface Props {
   actions: Array<Action>
   className?: string;
   buttonContent: any;
   buttonClassName?: string;
   onButtonClick: () => void;
   style?: object
}

interface Action {
   action: () => void;
   content: any;
   visible: boolean;
   className?: any;
}

export class StickyActions extends React.Component<Props, State> {
   constructor(props: any) {
      super(props)
      this.state = {
         expanded: false
      }
   }

   render() {
      const {expanded} = this.state;
      const {actions, className, buttonClassName, buttonContent, onButtonClick, style} = this.props;

      return (
         <ul className={classNames(styles.stickyActions, className)} style={style}>
            <button
               onClick={() => onButtonClick()}
               className={classNames(styles.stickyActionButton, buttonClassName)}
            >
               {buttonContent}
            </button>

            {actions.map(action => (
               action.visible &&
               <li className={classNames(styles.stickyActionItem, action.className)}>
                  <Button
                     type={ButtonTypes.Text}
                     color={ButtonColors.Action}
                     onClick={() => action.action()}
                  >
                     {action.content}
                  </Button>
               </li>
            ))}
         </ul>
      )
   }
}
