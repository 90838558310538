import styled from '@emotion/styled'
import {StatisticsCircleProps} from './types'
import {mediaTo} from '../../../styles/utils'

interface ButtonProps {
    isDisabled?: boolean
}

export const CircleWidgetContainer = styled.div<ButtonProps>`
   text-align: center;
   
   & * {
      transition: all .3s ease-in-out;
   }

   .widgetHeader {
      color: $grey;
      margin-bottom: 2rem;
      font-size: 1.4rem;
   }

   .pink {
      background: #dd7d92;

      &:active {
         box-shadow: 0 0 0 .8rem rgba(206, 55, 77, 0.2);
      }
   }

   .green {
      background: #bdce82;

      &:active {
         box-shadow: 0 0 0 .8rem rgba(189, 206, 130, 0.33);
      }
   }

   .blue {
      background: #78a0b2;

      &:active {
         box-shadow: 0 0 0 .8rem rgba(120, 160, 178, 0.33);
      }
   }

   .grey {
      background: #dfdfe0;
      color: #dfdfe0;

      .inner-circle {
         box-shadow: none !important;
      }
   }

   a {
      color: #000000;

      &:hover {
         color: #000000;
      }
   }

   .circleWidget {
      border-radius: 50%;
      height: 16rem;
      width: 16rem;
      position: relative;
      margin: 0 auto;
      background-color: ${({theme, isDisabled})=> isDisabled && theme.colors.greyLight};
      
      /*
     Child elements with absolute positioning will be
     positioned relative to this div
    */
      .innerCircle {
         position: absolute;
         background: #fbfbfb;
         border-radius: 50%;
         height: 12rem;
         width: 12rem;

         /*
       Put top edge and left edge in the center
      */
         top: 50%;
         left: 50%;
         transform: translate(-50%, -50%);
         /*
       Offset the position correctly with
       minus half of the width and minus half of the height
      */

         -webkit-box-shadow: 0 1rem .6rem -.6rem #777;
         -moz-box-shadow: 0 1rem .6rem -.6rem #777;
         box-shadow: 0 1rem .6rem -.6rem #777;

         .digit {
            position: absolute;
            text-align: center;
            width: 100%;
            vertical-align: text-bottom;
            font-size: 4rem;
            font-weight: bold;
            color: ${({theme, isDisabled})=> isDisabled && theme.colors.greyMedium};
            
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            ${mediaTo.tablet`
               font-size: 3.6rem;
            `}
            
         }

         &:hover {
            transform: translate(-50%, -50%) scale(1.1);
            box-shadow: 0 .1rem .2rem 0 rgba(0, 0, 0, 0.04),
            0 .1rem .6rem 0 rgba(0, 0, 0, 0.08),
            .1rem .3rem 1rem 0 rgba(0, 0, 0, 0.16),
            .2rem .5rem 2rem 0 rgba(0, 0, 0, 0.16),
            .5rem .9rem 3.6rem 0 rgba(0, 0, 0, 0.2),
            1rem 2.2rem 4.3rem 0 rgba(0, 0, 0, 0.3);

            // .digit {
            //    font-size: 40px;
            // }
         }

         ${mediaTo.tablet`
            width: 10.8rem;
            height: 10.8rem;
         `}
      }

      &:active {
         &:before {
            position: absolute;
            display: block;
            content: "";
            border: 0.213rem solid #fff;
            height: calc(100% - .426rem);
            width: calc(100% - .426rem);
            margin-top: 0.213rem;
            margin-left: 0.213rem;
            border-radius: 50%;
         }
      }

      ${mediaTo.tablet`
         width: 14.4rem;
         height: 14.4rem;
      `}
   }

    ${({isDisabled})=> isDisabled && `
      cursor: not-allowed;

      & * {
         pointer-events: none;
      }

    `} 
`