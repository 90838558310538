export const months = (lang) => {
   if (lang === "en") {
      return ['January','February','March','April','May','June','July','August','September','October','November','December']
   } else if (lang === "it") {
      return ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]
   } else if (lang === "fr") {
      return ['février','mars','avril','mai','juin','juillet','aout','janvier']
   } else {
      return ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];
   }
}
