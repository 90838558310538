import styled from "@emotion/styled";


export const StyledButton = styled.button`
   display: flex;
   border: none;
   outline: none;
   cursor: pointer;
   position: relative;
   align-items: center;
   transition: all .2s linear;
   background-color: transparent;

   color: ${({theme}) => theme.colors.action};
   font-size: 1.4rem;
   font-weight: bold;
   letter-spacing: 0;
   text-transform: uppercase;

   &:after {
      position: absolute;
      //content: "";
      opacity: 0;
      top: -.5rem;
      left: -.1rem;
      border-radius: .4rem;
      border: 2px solid ${({theme}) => theme.colors.action};
      transition: all .2s linear;
      width: calc(100% + .1rem);
      height: calc(100% + 1rem);
      box-shadow: 0 0 0 .7rem ${({theme}) => `rgba(${theme.colors.action},0.1)`};
   }

   &:focus {
      &:after {
         opacity: 1;
         transition: all .2s linear;
      }
   }

   &:disabled {
      color: ${({theme})=> theme.colors.greyMedium};
      cursor: not-allowed;

      &:focus {
         &:after {
            opacity: 0;
         }
      }

      .plusButtonCircle {
         background-color: ${({theme})=>theme.colors.greyLight};

         svg path {
            stroke: ${({theme})=> theme.colors.greyDisabled};
         }
      }
   }
`;

export const StyledPlusButtonCircle = styled.div`

   display: flex;
   align-items: center;
   justify-content: center;

   width: 4.8rem;
   height: 4.8rem;
   margin-right: 1.6rem;
   border-radius: 2.4rem;
   background-color: ${({theme})=>theme.colors.action};

   svg {
      transform: scale(1.2);
   }
`