import * as React from 'react'
import {FC} from 'react'
import classNames from 'classnames'

import styles from './Checkbox.module.scss'

interface IProps {
   isFocused: boolean;
   isLoading: boolean;
   name?: string;
   value?: string;
   fullWidth?: boolean;
   checked?: boolean;
   defaultValue?: boolean;
   readOnly?: boolean;
   label: string;
   type: string;
   onChange: (name: string, value: boolean) => void;
   additionalClass?: string;
   isDisabled?: boolean;
   onBlur: any;
}

const IconCheckbox: FC<{ isChecked: boolean; isLabel: boolean }> = ({isChecked, isLabel}) => (
   <svg
      className={classNames(styles.checkmark, {[styles.hasLabel]: isLabel})}
      width="18px"
      height="18px"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
   >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
         <g transform="translate(1.000000, -1.000000)" stroke="#222222" strokeWidth="2">
            <g stroke="#AFAFB1" transform="translate(0.000000, 2.000000)">
               <rect id="Rectangle" x="0" y="0" width="16" height="16" rx="2"/>

               {isChecked && (
                  <polyline
                     id="Path"
                     strokeLinecap="round"
                     strokeLinejoin="round"
                     points="11 6 6.875 10 5 8.18181818"
                  />
               )}
            </g>
         </g>
      </g>
   </svg>
);

export const InputCheckbox: FC<IProps> = (props) => (
   <label
      htmlFor={props.name}
      tabIndex={0}
      onBlur={props.onBlur}
      className={classNames(styles.label, {[styles.full]: props.fullWidth}, {[styles.checked]: props.checked})}
      onKeyPress={(event) => {
         var code = event.keyCode || event.which
         if (code === 13) {
            props.onChange(props.name, !props.checked)
         }
      }}
   >
      <IconCheckbox isChecked={props.checked} isLabel={!!props.label}/>

      {props.label}

      <input
         tabIndex={-1}
         id={props.name}
         className={styles.checkbox}
         type="checkbox"
         readOnly={props.readOnly}
         name={props.name}
         onBlur={props.onBlur}
         checked={props.checked}
         defaultChecked={props.defaultValue}
         value={props.value}
         disabled={props.isDisabled}
         onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            props.onChange(e.currentTarget.name, e.currentTarget.checked)
         }}
      />
   </label>
);
